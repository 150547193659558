import { useEffect, useState, useRef } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { getBillPrice, getQuoteRetrieval } from "../api";
import { PageCard } from "./PageCard";
import "./css/QuoteDisplay.css";

import cash from "../Icons/money.svg";
import eco from "../Icons/eco.png";
import lightning from "../Icons/lightning.svg";
import water from "../Icons/water.svg";
import wifi from "../Icons/wifi1.svg";
import wifi2 from "../Icons/wifi2.svg";
import gas from "../Icons/gas.svg"
import pizza from "../Icons/pizza.png";
import coffee from "../Icons/heybrew_mug.png"

import { checkScottishCity } from "../lib/utils";

export const QuoteRetrieval = () => {
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get("uuid");

    const [billPriceUnlimited, setBillPriceUnlimited] = useState("");
    const [billPriceUnlimitedDiscounted, setBillPriceUnlimitedDiscounted] = useState(null);
    const [billPackage, setBillPackage] = useState("");
    const [billPriceLite, setBillPriceLite] = useState("");
    const [user, setUser] = useState({});
    const [focusedDiv, setFocusedDiv] = useState(null);
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);
    const isScottishCity = checkScottishCity(user.city);
    const temporaryGate = true;


    const handleClickOutside = (event) => {
        if (
            div1Ref.current &&
            div2Ref.current &&
            div3Ref.current &&
            !div1Ref.current.contains(event.target) &&
            !div3Ref.current.contains(event.target) &&
            !div2Ref.current.contains(event.target)
        ) {
            setFocusedDiv(null);
        }
    };

    useEffect(() => {
        if (uuid) {
            getQuoteRetrieval(uuid).then((response) => {
                setUser(response);
                const isScottishCity = checkScottishCity(response.city);
                getBillPrice(response.bedrooms, isScottishCity).then((res) => {
                    if (response.discount_code) {
                        setBillPriceUnlimitedDiscounted(
                            (
                                res.price?.[0][0]?.price *
                                response.discountMultiplier
                            ).toFixed(2)
                        );
                    }
                    setBillPriceUnlimited(
                        parseFloat(res.price?.[0][0]?.price).toFixed(2)
                    );
                    setBillPriceLite(
                        parseFloat(res.price?.[1][0]?.price).toFixed(2)
                    );
                });
            });
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [uuid, user.bedrooms, billPriceUnlimited, billPriceLite]);

    const referral = searchParams.get("referral");
    let url = `/signup/tenants`;

    if (referral) {
        url += `?referral=${referral}`;
    }

    return (billPriceLite === "" || isNaN(billPriceLite) || isNaN(billPriceUnlimited) || isNaN(billPriceUnlimitedDiscounted)) ? (
        <div
            className="d-flex justify-content-center flex-column align-items-center w-100 border"
            style={{ height: "100vh" }}
        >
            <h3>Fetching your bill quote...</h3>
            <Spinner />
        </div>
    ) : (
        <div className="body" style={{ backgroundColor: "#f6f7fa" }}>
            <div className="d-flex justify-content-center">
                <PageCard quotePage cardColour="transparent">
                    <div className="d-flex flex-column mt-xl-5" style={{height: "100vh"}}>
                        <h1 className="fw-bold pt-xl-5">
                            Welcome back, {user.name.split(" ")?.[0]} 👋
                        </h1>
                        <div className="mb-3">
                            <h3 className="fw-bold">Your Personal Quote</h3>
                            <p>
                                For {user.bedrooms}{" "}
                                {user.bedrooms > 1 ? "housemates" : "housemate"}
                            </p>
                        </div>
                        <div className="option-buttons">
                            <div
                                className="quote-card-selection"
                                ref={div1Ref}
                                tabIndex="0"
                                onClick={() => {
                                    setFocusedDiv("div1");
                                    setBillPackage("unlimited");
                                }}
                            >
                                <div>
                                    <h3 className="package-name">
                                        Housr Unlimited
                                    </h3>
                                </div>
                                <div className="border-bottom pricing-container">
                                    
                                {billPriceUnlimitedDiscounted ? 
                                    <div>
                                        <div className="discount-banner">
                                            <p className="discount-banner-text">{Math.ceil((1 - user?.discountMultiplier) * 100)}% OFF</p>
                                        </div>
                                        <h1 className="price-unlimited" style={{
                                            textDecoration: "line-through",
                                            fontSize: 28
                                        }}>
                                            £{billPriceUnlimited}
                                        </h1>
                                        <h1 className="price-unlimited">
                                            £{billPriceUnlimitedDiscounted}
                                        </h1>
                                    </div>

                                    :  <h1 className="price-unlimited">
                                        £{billPriceUnlimited}
                                        </h1>}
                                    <p>per tenant a week</p>
                                </div>

                                <div className="container benefits-section">
                                    <div className="top-row">
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={gas}
                                                    alt="gas"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Unlimited Gas
                                                </p>
                                            </div>
                                        </div>

                                        {!isScottishCity && (
                                            <div className="row align-items-start utility-row">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "18px",
                                                    }}
                                                    className="utility"
                                                >
                                                    <img
                                                        src={water}
                                                        alt="water"
                                                        className="py-1 icon-height"
                                                    />
                                                    <p className="utility-font">
                                                        Unlimited Water
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="top-row">
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={lightning}
                                                    alt="lightning"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Unlimited Energy
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={wifi}
                                                    alt="wifi"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Internet (400 mbps)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!billPriceUnlimitedDiscounted && !temporaryGate && <div
                                className="quote-card-selection"
                                tabindex="0"
                                onClick={() => {
                                    setFocusedDiv("div2");
                                    setBillPackage("lite");
                                }}
                                ref={div2Ref}
                            >
                                <div className="mb-1">
                                    <h3 className="package-name">Housr Lite</h3>
                                </div>
                                <div className="border-bottom pricing-container">
                                    <h1 className="price-lite">
                                        £{billPriceLite}
                                    </h1>
                                    <p>per tenant a week</p>
                                </div>

                                <div className="container benefits-section">
                                    <div className="top-row">
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={cash}
                                                    alt="cash"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Cheaper
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={eco}
                                                    alt="eco"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Eco-friendly
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="top-row">
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={wifi2}
                                                    alt="wifi"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Internet (400 mbps)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        {(user.city === "Manchester" || user.city === "Birmingham") && (
                            <div className="quote-perk-container">
                                <div className="quote-perk">
                                    PLUS a year’s free {user.city === "Manchester" ? "Papa Johns Pizza" : "Hey Brew coffee"}
                                </div>
                                <div className="perk-img-container">
                                    <img
                                        src={user.city === "Manchester" ? pizza : coffee}
                                        style={{ height: "100%" }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="continue">
                            <div style={{ width: "100%" }}>
                                {focusedDiv !== null ? (
                                    <Link
                                        ref={div3Ref}
                                        to={url}
                                        state={{
                                            bedrooms: user.bedrooms,
                                            leadTenantId: user.id,
                                            leadTenantNumber: user.contact_no,
                                            leadTenantName: user.name,
                                            package: billPackage,
                                            discountedPrice: billPriceUnlimitedDiscounted,
                                            discountCode: user.discount_code
                                        }}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: "#109539",
                                                borderColor: "#109539",
                                                borderRadius: "20px",
                                                fontSize: "20px",
                                            }}
                                            className="fw-bold py-2 w-100"
                                        >
                                            Continue
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: "#109539",
                                            borderColor: "#109539",
                                            borderRadius: "20px",
                                            fontSize: "20px",
                                        }}
                                        className="fw-bold py-2 w-100"
                                        disabled
                                    >
                                        Continue
                                    </Button>
                                )}
                            </div>
                            <Link
                                to={"/signup/lead-tenant"}
                                className="link align-items-center d-flex w-100 justify-content-center"
                                style={{height: "5%", marginTop:"5%"}}
                                state={{
                                    user: user,
                                }}
                            >
                                Back
                            </Link>
                        </div>
                    </div>
                </PageCard>
            </div>
        </div>
    );
};
