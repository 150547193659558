const scottishCities = [
    "Aberdeen",
    "East Kilbride",
    "Cumbernauld",
    "Falkirk",
    "Wishaw",
    "Fraserburgh",
    "Govan",
    "Anstruther",
];
export const checkScottishCity = (city) => scottishCities.includes(city);