import axios from "axios";
import { checkScottishCity } from "./lib/utils";

export const getBillPrice = async (bedroom, discount, city) => {
    
    const country = checkScottishCity(city) ? "Scotland" : "England";

    return axios
        .get(`bill_prices/${bedroom}`, {
            params: {
                discount: discount,
                country: country,
                city: city,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getQuoteRetrieval = async (uuid) => {
    return axios.get(`/bill_prices/uuid/${uuid}`).then((res) => {
        return res.data[0];
    });
};

export const getLeaderboard = async () => {
    return axios.get(`/leaderboard`, { withCredentials: true }).then((res) => {
        return res.data;
    });
};

export const getAllBlogs = async () => {
    return axios.get(`/blogs`).then((res) => {
        return res.data;
    });
};
