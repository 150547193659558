import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
    Link,
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";
import gas from "../Icons/gas.svg";
import coffee from "../Icons/heybrew_mug.png";
import lightning from "../Icons/lightning.svg";
import pizza from "../Icons/pizza.png";
import water from "../Icons/water.svg";
import wifi from "../Icons/wifi1.svg";
import { getBillPrice, getQuoteRetrieval } from "../api";
import "./css/QuoteDisplay.css";
import { checkScottishCity } from "../lib/utils";

export const QuoteDisplay = () => {
    const { bedrooms } = useParams();
    let { state } = useLocation();

    const leadTenantId = state?.leadTenantId;
    const leadTenantNumber = state?.leadTenantNumber;
    const leadTenantName = state?.leadTenantName;
    const leadTenantEmail = state?.leadTenantEmail;
    const discount = state?.discount;
    const flatmateData = state?.flatmateData;
    const hubSpotContactIds = state?.hubSpotContactIds;
    const city = state?.city;

    const isScottishCity = checkScottishCity(city);

    const [searchParams] = useSearchParams();
    const uuid = searchParams.get("uuid");
    const [billPriceUnlimited, setBillPriceUnlimited] = useState("");
    const [billPriceUnlimitedDiscounted, setBillPriceUnlimitedDiscounted] =
        useState(null);
    const [discountMultiplier, setDiscountMultiplier] = useState(null);
    const [user, setUser] = useState({});
    
    useEffect(() => {
        if (uuid) {
            getQuoteRetrieval(uuid).then((response) => {
                setUser(response);
                setDiscountMultiplier(response.discountMultiplier);
                getBillPrice(user.bedrooms).then((res) => {
                    if (response.discount_code) {
                        setBillPriceUnlimitedDiscounted(
                            (
                                res.price?.[0][0]?.price *
                                response.discountMultiplier
                            ).toFixed(2)
                        );
                    }
                    setBillPriceUnlimited(
                        parseFloat(res.price?.[0][0]?.price).toFixed(2)
                    );
                });
            });
        } else {
            getBillPrice(bedrooms, discount, city).then((res) => {
                
                const price = res.price?.[0][0]?.price;

                setDiscountMultiplier(res.discount);
                if (res.discount) {
                    setBillPriceUnlimitedDiscounted(
                        (price * res.discount).toFixed(2)
                    );
                }
                setBillPriceUnlimited(
                    parseFloat(city === "Leeds" ? price - 3 : price).toFixed(2)
                );
            });
        }
    }, [uuid, user.bedrooms, bedrooms, billPriceUnlimited]);

    const referral = searchParams.get("referral");
    let url = `/signup/tenants`;

    if (referral) {
        url += `?referral=${referral}`;
    }

    return isNaN(billPriceUnlimited) ||
        isNaN(billPriceUnlimitedDiscounted) ? (
        <div
            className="d-flex justify-content-center flex-column align-items-center w-100 border"
            style={{ height: "100vh" }}
        >
            <h3>Fetching your bill quote...</h3>
            <Spinner />
        </div>
    ) : (
        <div className="body" style={{ backgroundColor: "#f6f7fa" }}>
            <div className="d-flex justify-content-center">
                <div
                    className="d-flex flex-column mt-xl-5 align-items-center"
                    style={{ height: "100vh" }}
                >
                    <div className="justify-content-center align-items-center d-flex flex-column" style={{marginTop: "10vh", marginBottom: "8vh"}}>
                        <h1 className="fw-bold">Your Quote</h1>
                        <div className="d-flex flex-row w-100 justify-content-center">
                            <p className="justify-content-center">
                                For {bedrooms}{" "}
                                {bedrooms > 1 ? "housemates" : "housemate"}
                            </p>
                        </div>
                    </div>
                    <div className="option-buttons">
                        <div
                            className="quote-card-selection"
                            tabIndex="0"
                        >
                            <div>
                                <h3 className="package-name">
                                    Housr Unlimited
                                </h3>
                            </div>
                            <div
                                className="border-bottom pricing-container"
                                style={{ width: "85%" }}
                            >
                                {billPriceUnlimitedDiscounted ? (
                                    <div>
                                        <div className="discount-banner">
                                            <p className="discount-banner-text">
                                                {Math.ceil(
                                                    (1 - discountMultiplier) *
                                                        100
                                                )}
                                                % OFF
                                            </p>
                                        </div>
                                        <h1
                                            className="price-unlimited"
                                            style={{
                                                textDecoration: "line-through",
                                                fontSize: 28,
                                            }}
                                        >
                                            £{billPriceUnlimited}
                                        </h1>
                                        <h1 className="price-unlimited">
                                            £{billPriceUnlimitedDiscounted}
                                        </h1>
                                    </div>
                                ) : (
                                    <h1 className="price-unlimited">
                                        £{billPriceUnlimited}
                                    </h1>
                                )}

                                <p>per tenant a week</p>
                            </div>

                            <div className="container benefits-section">
                                <div className="top-row">
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={gas}
                                                alt="gas"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Unlimited Gas
                                            </p>
                                        </div>
                                    </div>

                                        {!isScottishCity && (
                                            <div className="row align-items-start utility-row">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "18px",
                                                    }}
                                                    className="utility"
                                                >
                                                    <img
                                                        src={water}
                                                        alt="water"
                                                        className="py-1 icon-height"
                                                    />
                                                    <p className="utility-font">
                                                        Unlimited Water
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div className="top-row">
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={lightning}
                                                alt="lightning"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Unlimited Energy
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={wifi}
                                                alt="wifi"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Internet (400 mbps)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(city === "Manchester" || city === "Birmingham") && (
                        <div className="quote-perk-container">
                            <div className="quote-perk">
                                PLUS a year’s FREE{" "}
                                {city === "Manchester"
                                    ? "Papa Johns' Pizza"
                                    : "Hey Brew coffee"}
                            </div>
                            <div className="perk-img-container">
                                <img
                                    src={city === "Manchester" ? pizza : coffee}
                                    style={{ height: "100%" }}
                                />
                            </div>
                        </div>
                    )}

                    <div className="continue">
                        <div style={{ width: "100%" }}>
                                <Link
                                    to={url}
                                    state={{
                                        bedrooms: bedrooms,
                                        leadTenantId: leadTenantId,
                                        leadTenantNumber: leadTenantNumber,
                                        leadTenantName: leadTenantName,
                                        leadTenantEmail: leadTenantEmail,
                                        package: "unlimited",
                                        discountedPrice:
                                            billPriceUnlimitedDiscounted,
                                        discountCode: discount,
                                        flatmateData,
                                        hubSpotContactIds,
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: "#109539",
                                            borderColor: "#109539",
                                            borderRadius: "20px",
                                            fontSize: "20px",
                                        }}
                                        className="fw-bold py-2 w-100"
                                    >
                                        Continue
                                    </Button>
                                </Link>
                        </div>
                        <Link
                            to={"/"}
                            className="link align-items-center d-flex w-100 justify-content-center"
                            style={{
                                height: "15vh",
                            }}
                                state={
                                    {
                                        bedrooms: bedrooms,
                                        leadTenantId: leadTenantId,
                                        leadTenantNumber: leadTenantNumber,
                                        leadTenantName: leadTenantName,
                                        leadTenantEmail: leadTenantEmail,
                                        package: "unlimited",
                                        discountedPrice:
                                            billPriceUnlimitedDiscounted,
                                        discountCode: discount,
                                        flatmateData,
                                        hubSpotContactIds,
                                    }
                            }
                        >
                            Back
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
